import HomePage from './Components/Home';
import Footer from '../Footer';

function App() {

  

  return (
    <>
   <HomePage />
   <div className="bingo-nav">
   <Footer />
   </div>
    </>
  );
}

export default App;
