const WesleyHeader = () => {
    return ( 
        <header className="wesley-header">
        <div className="header-wrapper">
        <div className="bg"> WESLEY SNOPES </div>
        <div className="fg"> WESLEY SNOPES </div>
        </div>
        <br />
        <div className="sh">A fact-checking service</div>
        </header>
     );
}
 
export default WesleyHeader;

