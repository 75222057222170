import './BFApp.css';
import Container from './container';

function BigFinish() {
  return (
    <>
     <Container />
    </>
  );
}

export default BigFinish;
