import './Header.css';

const Header = () => {
    return ( 
        <div className="Heading">
            <h1><span className="fancy">Andrew  Blair's Portfolio</span></h1>
        </div>
     );
}
 
export default Header;