const AboutMe = () => {
   

    return ( 

            <summary className='About-Me'>
            I am a junior software developer, writer and performer based in Edinburgh.
                </summary>
            )}


 
export default AboutMe;